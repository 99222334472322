import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {
    
    static targets = [ "tabs", "panels"]

    panels = []
    triggers = []

    connect() {
        this.triggers = this.tabsTarget.querySelectorAll('[data-role="tab"]')
        if(this.triggers){
            for(let el of this.triggers){
                el.addEventListener('click', (event) => {
                    event.preventDefault()
                    let target = event.target.getAttribute('target')
                    this.activeTrigger(event.target) 
                    this.activePanel(target)
                })
            }
        }

        this.panels = this.panelsTarget.querySelectorAll('[role="tabpanel"]')
        console.log('panels', this.panels)
    }

    disconnect() {
        let els = this.tabsTarget.querySelectorAll('[data-role="tab"]')
        if(els){
            for(let el of els){
                el.removeEventListener('click')
            }
        }

        this.panels = null
        this.triggers = null
    }

    activeTrigger(trigger){
        if(this.triggers){
            for(let trigger of this.triggers){
                trigger.removeAttribute('selected')
            }
        }

        trigger.setAttribute('selected',true)
    }

    activePanel(target) {

        let panel = this.panelsTarget.querySelector(`#${target}`)

        this.panels.forEach((panel) => {
            panel.setAttribute('hidden', true)
        })

        if(panel){
            panel.removeAttribute('hidden')
        }
    }
}
