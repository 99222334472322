import { Controller } from '@hotwired/stimulus'
import SmilesDrawer from "smiles-drawer"

export default class extends Controller {

  static values = {width: Number, height: Number }

  initialize(){
    console.log('drawer many')
  }

  connect(){

    // console.log('this.hasWidthValue',this.hasWidthValue)

    let w = this.hasWidthValue  ? this.widthValue   : 120
    let h = this.hasHeightValue ? this.heightValue  : 120

    let options = {
      width:w,
      height:h
    };

    // console.log('options',options)

    let sd = new SmilesDrawer.SmiDrawer(options, []);
    let elements = document.querySelectorAll('svg[data-smiles]');

    for(let element of elements){
      sd.draw(element.getAttribute('data-smiles'), element, 'carbon')

    }

  }
}