import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ["modalSmiles", "modalInchi"]

    connect(){

        setTimeout(()=>{
            let wrapper = document.querySelector('#modal-wrapper')
            if(wrapper){
                wrapper.style.display = 'block'
            }

        }, 1000)

    }

    exportInchiKey(event){
        let modal = document.querySelector('#modal-inchikey')
        if(modal){
            modal.classList.remove('hidden')
            modal.open = true
        }
    }

    exportInchi(event){
        let modal = document.querySelector('#modal-inchi')
        if(modal){
            modal.classList.remove('hidden')
            modal.open = true
        }
    }

    exportSmiles(event){
        let modal = document.querySelector('#modal-smiles')
        if(modal){
            modal.classList.remove('hidden')
            modal.open = true
        }    
    }
}
