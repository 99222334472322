import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

	static values = {
		pageSize: String
	}

    pageChange(event){

    	let start = event.detail.start
    	let page =  Math.ceil((start / this.pageSizeValue)) +1

    	console.log('start',start)
    	console.log('page',page)

    	const parser = new URL(window.location);
    	parser.searchParams.set('page', page);
    	window.location = parser.href;
    }
}



