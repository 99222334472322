import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ["molecule", "molecularGroup", "plant"]

    reset(event){
    }

    connect(){
        console.log('connect')
	this.moleculeTarget.value= ''
	this.molecularGroupTarget.value =''
	this.plantTarget.value = ''
    }

    submit(event){
        event.preventDefault()
        let isValid = this.validate()
        if(isValid){
            this.element.submit()
        }
    }

    validate(){

        let molecule = this.moleculeTarget.value
        let group = this.molecularGroupTarget.value
        let plant = this.plantTarget.value

        if(molecule == '' && group == '' && plant == ''){
            return false
        }

        return true


    }

}
