import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    static targets = ["template", "moleculeGroup", "modal"]

    addMolecule(event) {
        
        let clone       = null
        let content     = ''
        let template    = document.createElement('template')
        let index       = this.moleculeGroupTarget.querySelector('[data-form-group-item]')?.length || 1

        template.innerHTML = this.templateTarget.innerHTML.replace(/__index__/g, index);

        content = template.content

        clone = document.importNode(content, true);
        
        this.moleculeGroupTarget.appendChild(clone) 
    }

    deleteMolecule(event){
        let item = this.moleculeGroupTarget.querySelector('[data-form-group-item]:last-child')
        if(item){
            item.parentNode.removeChild(item)
        }
    }

    submit(event){
        event.preventDefault()
        if(this.validate()){
            this.modalTarget.open = true
            this.element.querySelector('form').submit()
        }
    }

    validate(){

        let isValid = true

        let required = this.element.querySelector('form').querySelectorAll('[required]')

        for(let node of required){
            if(!node.checkValidity()){
                isValid = false
                node.setAttribute('invalid', true)
            }else{
                isValid = true
                node.removeAttribute('invalid')
            }
        }

        return isValid
    }
}
