import { Controller } from '@hotwired/stimulus'
import SmilesDrawer from "smiles-drawer"

export default class extends Controller {

  static values = { smiles: String, width: Number, height: Number }

  connect(){

    let options = {
      width: this.hasWidthValue ? this.widthValue : 500,
      height: this.hasHeightValue ? this.heightValue : 250,
      padding: 0.0,
      // bondThickness:1.8,
      // shortBondLength:0.7,
      terminalCarbons: true,
      // scale:2
    };

    if(this.hasWidthValue){
      options.width = this.widthValue
    }

    if(this.hasHeightValue){
      options.height = this.heightValue
    }

    console.log('options', options)

    let sd = new SmilesDrawer.SmiDrawer(options, []);

    sd.draw(this.smilesValue, '#drawer-svg', 'carbon')

  }

}