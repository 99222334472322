import { Controller } from '@hotwired/stimulus'
import { ApplicationController, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {

  static debounces = ['change']
  static targets    = ["datalist", "input", "id", "loading"]
  static values     = { 
    url: String
  }

  controler = null
  controllers = []
  hasCurrentRequest = false

  connect(){
    useDebounce(this, { wait: 600 })
  }

  initialize(){
    document.addEventListener('autocomplete-url-change', (e) => {
      let content = e.detail.content
      if(content){
        this.urlValue = content
      }
    })
  }

  async change(event) {

    event.preventDefault()
    let mode = 'start'

    let searchMode = this.element.querySelector('[name="search[mode]"]')
    if(searchMode){
      mode = searchMode.value || 'start'
    }

    let el = event.target
    let value = el.value.trim()
  
    if(value.length == 0){
      this.resetDatalist()
      return false 
    }

    if(value.length >= 2){
        let result = await this.request(value, mode)
        if(result){
          this.updateDatalist(result)
        }else{
          this.resetDatalist()
        }
    }
  }

  async request(pattern, mode){

    let response    = null
    let controller  = null

    for(let item of this.controllers){
      item.abort()
    }
    this.controllers = []

    controller = new AbortController()

    this.controllers.push(controller)
    
    this.activateLoading()

    let fetchOptions = {
      signal: controller.signal,
      method: "POST",
      body: JSON.stringify({lookup : pattern, mode:mode})
    }

    try{
      response = await (await fetch(this.urlValue, {...fetchOptions} )).text()       
    }catch(e){
      this.desactivateLoading()
    }

    for(let item of this.controllers){
      item.abort()
    }
    this.controllers = []

    this.desactivateLoading()

    return response
  }

  updateDatalist(html){
    this.datalistTarget.innerHTML = html
  }

  resetDatalist(){
    this.datalistTarget.innerHTML = ''   
  }

  selectItem(event){
    let selectedValue = event.target.dataset.value
    this.datalistTarget.innerHTML = ''
    this.inputTarget.value = event.target.dataset.text
    this.idTarget.value = event.target.dataset.value
    this.inputTarget.focus()
    this.dispatch("updated", { detail: { content: selectedValue } })
    
  }

  clickOutside(event) {
    // event.preventDefault()
    this.resetDatalist()
  }

  activateLoading(){
    if(this.hasLoadingTarget){
      this.loadingTarget.setAttribute('status', 'active')    
    }
  }

  desactivateLoading(){
    if(this.hasLoadingTarget){
      this.loadingTarget.setAttribute('status', '')
    }
  }

}