import { Controller } from '@hotwired/stimulus';

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends Controller {

    // static values = {
    //     url: String
    // }
    static targets = ["result", "resultContainer", "modal"]

    async submit(event){
        
        let smiles = null

        //le champs de texte est-il rempli ?
        let input = document.querySelector('#smilesInput')

        if(input && input.value != ''){
            smiles = input.value.trim()
        }
        else{
            let ketcherFrame = this.element.querySelector('iframe');

            if ('contentDocument' in ketcherFrame)
                this.ketcher = ketcherFrame.contentWindow.ketcher;
            else // IE7
                this.ketcher = document.frames['ifKetcher'].window.ketcher;
            
            smiles = await this.ketcher.getSmiles(true)            
        }
        
        this.modalTarget.open = true    
        let result = await this.requestPrediction(smiles)
        this.modalTarget.open = false
        
        if(result.status == 'success'){
            this.resultContainerTarget.classList.remove('d-none')
            this.resultTarget.innerHTML = result.data.html
            this.resultTarget.scrollIntoView({ behavior: "smooth", block: "start", inline: "start" })
       }
        else{
            alert('An error occurs !')
        }
    }

    async requestPrediction(smiles){
        let encodedSmiles = encodeURIComponent(smiles)
        return await (await fetch(encodeURI('/predict/' + encodedSmiles))).json()
    }
}
