import { Controller } from '@hotwired/stimulus'
import { ApplicationController, useDebounce } from 'stimulus-use'

/*
 * This is an example Stimulus controller!
 *
 * Any element with a data-controller="hello" attribute will cause
 * this controller to be executed. The name "hello" comes from the filename:
 * hello_controller.js -> "hello"
 *
 * Delete this file or adapt it for your use!
 */
export default class extends ApplicationController {

    static classes    = ["hidden"] 
    static debounces  = ["filter"]

    currentPatterns   = {}
    filterableItems   = []

    connect(){
        console.log('filter-list Controller')
        useDebounce(this, {wait: 300})
        this.filterableItems = document.querySelectorAll('[data-filterable-item]')        
    }



    comboBoxChange(event){

        console.log('item', event.detail.item)        

        if(event.detail.item != undefined){

            let pattern =  event.detail.item.value.toLowerCase().trim()
            console.log('pattern', pattern)


           this.currentPatterns[event.params.name] = pattern
           this.filter()
        }else{
           delete this.currentPatterns[event.params.name]
           this.resetFilter()
       } 
    }

    selectChange(event){

        console.log("selectChange", event.detail.item.value)
        return false
        let pattern =  event.detail.value.toLowerCase().trim()

        if(pattern != ''){
            this.currentPatterns[event.params.name] = pattern
            this.filter()
        }else{
            delete this.currentPatterns[event.params.name]
            this.resetFilter()
        }

    }

    checkboxChange(event){
        console.log('checkboxChange')
        if(event.target.checked){
            let pattern =  event.params.pattern.trim()
            this.currentPatterns['checkbox'] = pattern
            this.filter()
        }else{
            delete this.currentPatterns['checkbox']
            this.resetFilter()
        }

    }

    inputChange(event){

        let pattern =  event.detail.value.toLowerCase().trim() 

        console.log('pattern', pattern)

        if(pattern != ''){
            this.currentPatterns['input'] = pattern
            this.filter()
        }
        else{
            delete this.currentPatterns['input']
            this.resetFilter()
        }
        
    }

    filter(e){

        for(const item of this.filterableItems){

            let hide = false

            for(const pattern of Object.values(this.currentPatterns)){

                if(!item.dataset.filterableValue.toLowerCase().includes(pattern)){
                    hide = true
                }
            }

            if(hide){
                item.classList.add(this.hiddenClass)
            }else
            {
                item.classList.remove(this.hiddenClass)
            }
        }

    }

    resetFilter(){
        //si aucun filtre en cours
        if(Object.keys(this.currentPatterns).length == 0){
            for(const item of this.filterableItems){
                item.classList.remove(this.hiddenClass)
            }
        } 
        else{
            this.filter()
        }
    }
}
